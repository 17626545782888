<template>
  <div class="RepreneurCompanyProfile" >
    <div class="container">
      <h1 class="gavc-h1 text-center mb-4">Mon entreprise</h1>

      <b-form v-on:submit.prevent="modifyCompany()">
        <div
           class="gavc-field">
          <label class="gavc-label" for="edit-company-company.qualite-input">
            Vous agissez en qualité de
            <span class="gavc-required"> * </span>
          </label>
          <select class="gavc-select" v-model="company.qualite" name="edit-company-company.qualite-input">
            <option v-for="option in qualiteOptions" :key="option.value" class="gavc-select-option" :value="option.value">
              {{option.text}}
            </option>
          </select>
          <inputErrorValidationMessage
            v-if="$v.company.qualite.$invalid"
            message="Veuillez renseigner cette information"
          />
        </div>

        <div
          class="gavc-field"
          v-bind:class="{ 'is-error': $v.company.name.$invalid }"
        >
          <label class="gavc-label" for="edit-company-company.name-group">
              La raison sociale de votre entreprise est
              <span class="gavc-required"> * </span>
              <i
                v-b-tooltip.hover.right="{ variant: 'light' }"
                title="La raison sociale correspond au nom officiel que vous avez déclaré lors de la création de votre entreprise. Elle figure sur son extrait d’immatriculation et dans les statuts de votre société."
                class="ml-2 gavc-text-martinique gavc-icon gavc-icon-bulb">
              </i>
          </label>
          <input
            class="gavc-input"
            id="edit-company-company.name-group"
            maxlength="250"
            placeholder="Raison sociale"
            @input="$v.company.name.$touch()"
            type="text"
            v-model="company.name"
          >
          <inputErrorValidationMessage
            v-if="$v.company.name.$invalid"
            message="La raison sociale de votre entreprise doit comporter entre 2 et 250 caractères"
          />
        </div>
        <div
           class="gavc-field"
           v-bind:class="{ 'is-error': $v.company.siren.$invalid }">
          <label class="gavc-label" for="edit-company-company.siren-input">
              Votre SIREN est le
              <span class="gavc-required"> * </span>
              <i
                class="gavc-text-martinique gavc-icon gavc-icon-bulb"
                v-b-tooltip.hover.right="{ variant: 'light' }"
                title="Le SIREN est le numéro d’identification unique de votre entreprise délivré par l’Insee lors de son immatriculation au
                       registre national des entreprises. Si vous le cherchez, il apparaît sur toutes les factures et devis émis par votre entreprise.
                       Vous pouvez également vous connecter sur le site de l’Insee ou Societe.com pour le retrouver.">
              </i>
          </label>
          <div class="gavc-field">
            <input
              class="gavc-input"
              maxlength="11"
              @input="$v.company.siren.$touch()"
              id="edit-company-company.siren-input"
              placeholder="XXX XXX XXX"
              v-mask="'### ### ###'"
              v-model="maskedSiren"
            >
            <inputErrorValidationMessage
              v-if="$v.company.siren.$invalid"
              message="Le SIREN de votre entreprise doit être composé de 9 chiffres"
            />
          </div>
        </div>
        <div
           class="gavc-field"
           v-bind:class="{ 'is-error': $v.company.forme_sociale.$invalid }"
         >
          <label class="gavc-label" for="edit-company-company.forme_sociale-input">
            La forme sociale de l'entreprise est
            <span class="gavc-required"> * </span>
          </label>
          <select class="gavc-select" v-model="company.forme_sociale" name="edit-company-company.forme_sociale-input">
            <option v-for="option in formeSocialeOptions" :key="option.value" class="gavc-select-option" :value="option.value">
              {{option.text}}
            </option>
          </select>
          <i
            v-b-tooltip.hover.right="{ variant: 'light' }"
            title="Si vous cherchez la forme sociale / statut juridique de votre entreprise, consultez Societe.com avec votre numéro de
                   SIREN, de SIRET ou en tapant le nom de votre entreprise."
            class="ml-2 gavc-text-martinique gavc-icon gavc-icon-bulb">
          </i>
          <inputErrorValidationMessage
            v-if="$v.company.forme_sociale.$invalid"
            message="Veuillez renseigner cette information"
          />
        </div>

        <div
           class="gavc-field"
           v-bind:class="{ 'is-error': $v.company.procedure_collective.$invalid }"
         >
          <label class="gavc-label" for="edit-company-company.procedure_collective-input">
            L'entreprise fait-elle l’objet d’une procédure collective ?
            <span class="gavc-required"> * </span>
          </label>
          <select class="gavc-select" v-model="company.procedure_collective" name="edit-company-company.procedure_collective-input">
            <option v-for="option in procedureCollectiveOptions" :key="option.value" class="gavc-select-option" :value="option.value">
              {{option.text}}
            </option>
          </select>
          <i
            v-b-tooltip.hover.right="{ variant: 'light' }"
            title="Si votre entreprise fait face à des difficultés, sélectionnez dans la liste déroulante le le type de procédure collective qui vous concerne.
                   Cette information ne sera pas affichée sur votre annonce de cession. Elle sera uniquement communiquée au candidat repreneur que vous avez identifié. "
            class="ml-2 gavc-text-martinique gavc-icon gavc-icon-bulb">
          </i>
          <inputErrorValidationMessage
            v-if="$v.company.procedure_collective.$invalid"
            message="Veuillez renseigner cette information"
          />
        </div>



        <div class="row mt-3">
          <div class="col-12 col-md-6">
            <div
              class="gavc-field"
              v-bind:class="{ 'is-error': $v.company.capital.$invalid }"
            >
              <label class="gavc-label" for="edit-company-company.capital-input">
                  Capital
                  <span class="gavc-required"> * </span>
                  <i
                    class="gavc-text-martinique gavc-icon gavc-icon-bulb"
                    v-b-tooltip.hover.right="{ variant: 'light' }"
                    title="Le capital social est la somme de tous les types d’apports (en numéraire, en nature, en industrie) que vous et vos éventuels associés/actionnaires
                           ont apportés dans l’entreprise lors de sa création en échange de droits sociaux."
                  >
                  </i>
              </label>
              <div class="gavc-field gavc-field--icon">
                <input
                  class="gavc-input gavc-input--icon"
                  @input="$v.company.capital.$touch()"
                  id="edit-company-company.capital-input"
                  placeholder="XX"
                  type="text"
                  maxlength="8"
                  v-model.lazy="maskedCapital"
                  v-money="maskedCapitalOptions"
                >
                <i class="gavc-icon gavc-icon-euro" ></i>
              </div>
              <inputErrorValidationMessage
                v-if="$v.company.capital.$invalid"
                message="Le capital doit être compris entre 1 € et 10 000 000 €"
              />
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="gavc-field"
                v-bind:class="{ 'is-error': $v.company.date_creation.$invalid }"
            >
              <label class="gavc-label" for="edit-company-company.date_creation-input">
                Date de création
                <span class="gavc-required"> * </span>
              </label>
              <div class="gavc-field gavc-field--icon">
                <input
                  @input="$v.company.date_creation.$touch()"
                  class="gavc-input gavc-input--icon gavc-input--calendar"
                  id="edit-company-company.date_creation-input"
                  type="date"
                  v-model="company.date_creation"
                >
                <i class="gavc-icon gavc-icon-calendar" ></i>
              </div>
              <inputErrorValidationMessage
                v-if="$v.company.date_creation.$invalid"
                message="Veuillez renseigner la date de création de votre entreprise"
              />
            </div>
          </div>
        </div>

        <hr>

        <p class="gavc-chapo">L'adresse du siège social :</p>

        <div
          class="gavc-field"
          v-bind:class="{ 'is-error': $v.company.address.$invalid }">
          <label class="gavc-label" for="edit-company-company.address-group">
              Adresse
              <span class="gavc-required"> * </span>
          </label>
          <input
            class="gavc-input"
            id="edit-company-company.address-group"
            maxlength="250"
            @input="$v.company.address.$touch()"
            type="text"
            v-model="company.address"
          >
          <inputErrorValidationMessage
            v-if="$v.company.address.$invalid"
            message="L'adresse doit comporter entre 2 et 250 caractères"
          />
        </div>

        <div class="row mt-3">
          <div class="col-12 col-md-6">
            <div
              class="gavc-field"
              v-bind:class="{ 'is-error': $v.company.zip_code.$invalid }">
              <label class="gavc-label" for="edit-company-company.zip_code-group">
                Code postal
                <span class="gavc-required"> * </span>
              </label>
              <input
                class="gavc-input"
                id="edit-company-company.zip_code-group"
                maxlength="5"
                @input="$v.company.zip_code.$touch()"
                type="text"
                v-model="company.zip_code"
              >
              <inputErrorValidationMessage
                v-if="$v.company.zip_code.$invalid"
                message="Le code postal doit être composé de 5 chiffres"
              />
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div
              class="gavc-field"
              v-bind:class="{ 'is-error': $v.company.city.$invalid }">
              <label class="gavc-label" for="edit-company-company.city-group">
                Ville
                <span class="gavc-required"> * </span>
              </label>
              <input
                class="gavc-input"
                id="edit-company-company.city-group"
                maxlength="128"
                @input="$v.company.city.$touch()"
                type="text"
                v-model="company.city"
              >
              <inputErrorValidationMessage
                v-if="$v.company.city.$invalid"
                message="La ville doit comporter entre 2 et 128 caractères"
              />
            </div>
          </div>
        </div>
        <button
          class="gavc-btn float-right mt-4"
          type="submit"
          :disabled="isInvalidCompany">
            Enregistrer
        </button>
      </b-form>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { integer, required, minLength, maxLength, maxValue, minValue, numeric} from 'vuelidate/lib/validators'

import { APIRepreneur } from '@/api/APIRepreneur'
import { isDate } from '@/validators/validators'
import { formeSocialeList } from '@/variables/formeSociale'
import { defaultMoneyMaskOptions } from "@/variables/masks/moneyMasks"
import { procedureCollectiveList } from '@/variables/procedureCollective'
import { qualiteList } from '@/variables/qualite'

const inputErrorValidationMessage = () => import('@/components/inputs/inputErrorValidationMessage')

const apiRepreneurService = new APIRepreneur()

export default {
  name: 'RepreneurCompanyProfile',
  components: {
    inputErrorValidationMessage
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {
      formeSocialeOptions: formeSocialeList,
      procedureCollectiveOptions: procedureCollectiveList,
      qualiteOptions: qualiteList,
      company: {
        address: '',
        capital: '',
        city: '',
        date_creation: '',
        forme_sociale: '',
        procedure_collective: '',
        qualite: '',
        name: '',
        siren: '',
        zip_code: ''
      },

      maskedSiren: '',
      maskedCapital: 0,
      maskedCapitalOptions: defaultMoneyMaskOptions
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isInvalidCompany () {
      if (this.$v.$invalid) {
        return true
      }
      return false
    }
  },
  validations: {
    company: {
      address: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(255)
      },
      capital: {
        required,
        numeric,
        minValue: minValue(1),
        maxValue: maxValue(100000000)
      },
      city: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(127)
      },
      date_creation: {
        required,
        validDate(value) {
          return isDate(value)
        }
      },
      forme_sociale: {
        required,
      },
      procedure_collective: {
        required,
      },
      qualite: {
        required,
      },
      name: {
        minLength: minLength(2),
        maxLength: maxLength(255),
        required
      },
      siren: {
        integer,
        required,
        minLength: minLength(9),
        maxLength: maxLength(9)
      },
      zip_code: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(5),
        integer
      }
    }
  },
  created: function() {
    this.getCompany()
  },
  watch: {
    maskedCapital(value) {
      this.company.capital = Number(value.replace(/ /g,''))
    },
    maskedSiren(value) {
      this.company.siren = value.replace(/ /g,'')
    }
  },
  methods: {

    getCompany () {
      this.$store.commit('loadFromAPI')
      apiRepreneurService.getConnectedRepreneurCompany(this.token)
        .then((result) => {
          delete result.data.repreneur
          this.company = result.data
          this.maskedSiren = this.company.siren
          this.maskedCapital = this.company.capital
        })
        .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('RepreneurCompanyProfile getCompany API Error : ' + String(error))
        })
        .finally(() => {
          this.$store.commit('endLoadingFromAPI')
        })
    },

    modifyCompany () {
      this.$store.commit('loadFromAPI')
      apiRepreneurService.modifyCompany(this.token, this.company)
      .then(() => {
        this.$bvToast.hide('notification-toast')
        this.$bvToast.toast('Votre entreprise a bien été mise à jour', {
          id: 'notification-toast',
          title: 'Entreprise mise à jour',
          variant: 'success',
          solid: true
        })
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('RepreneurCompanyProfile modifyCompany API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })

    },
  }
}
</script>
